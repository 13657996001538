<template>
  <div>
    <btn-back class="mb-4" :to="{ name: 'sales-index' }" />

    <template v-if="sale">
      <h2 class="mb-4">Редактирование распродажи</h2>

      <v-sheet class="pa-4" elevation="3">
        <sale-form :sale="sale" :loading="updating" @change-field="changeField" @submit="updateSale" />
      </v-sheet>
    </template>

    <h2 v-else-if="!loading" class="mb-4">Распродажа с id "{{ id }}" не найдена</h2>
  </div>
</template>

<script>
import { ApiValidationError } from "~/core/api-validation-error";

import { editorForm } from "~/mixins/multi-editor/editor-form";

import { Sale } from "~/libs/sales/sale";

import SaleForm from "~/components/sales/form";

export default {
  mixins: [editorForm],
  data() {
    return {
      sale: null,

      loading: true,
      updating: false
    };
  },
  created() {
    this.fetchSale();
  },
  computed: {
    id() {
      return this.$route.params.id;
    }
  },
  methods: {
    async fetchSale() {
      this.loading = true;

      try {
        this.sale = await this.$axios.$get(`discounts/sales/${this.id}`).then(sale => Sale.from(sale));
      } catch (e) {
        const html = new ApiValidationError(e).toHtml();

        this.$snackbar.error(`Распродажа с id: "${this.id}" не найдена ${html}`);
      } finally {
        this.loading = false;
      }
    },
    async updateSale() {
      if (this.updating) return;

      this.updating = true;

      try {
        this.sale = await this.$axios
          .$put(`discounts/sales/${this.id}`, Sale.to(this.sale))
          .then(sale => Sale.from(sale));

        this.$snackbar.success("Обновлено");
      } catch (e) {
        const html = new ApiValidationError(e, Object.keys(this.sale)).toHtml();

        this.$snackbar.error("Не обновлено" + html);
      } finally {
        this.updating = false;
      }
    },

    changeField(payload) {
      this.commitChange(this.sale, payload);
    }
  },
  components: {
    SaleForm
  }
};
</script>
