export class Sale {
  static from(saleDto) {
    const dto = JSON.parse(JSON.stringify(saleDto));

    for (const discount of dto.discounts) {
      // Перевод скидки в % в диапазон 0-100 из   0-1
      if (discount.type === "relative") {
        discount.value *= 100;
      }
    }

    return dto;
  }

  static to(saleDto) {
    const dto = JSON.parse(JSON.stringify(saleDto));

    for (const discount of dto.discounts) {
      discount.value = +discount.value;

      // Перевод скидки в % в диапазон 0-1 из  0-100
      if (discount.type === "relative") {
        discount.value /= 100;
      }

      if (discount.trial === "") {
        discount.trial = null;
      }
    }

    return dto;
  }
}
